<template>
  <div class="page-container">
    <div v-if="$vuetify.breakpoint.mdAndUp" class="primary picture-container">
      <v-img :src="homeImg" height="100vh"></v-img>
    </div>
    <div class="form-container">
      <div :style="formContentStyle">
        <SignupForm />
        <div class="switch text-body primary--text">
          <div>
            {{ loginText }} <router-link :to="loginLink.to">{{ loginLink.text }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeImg from '@/assets/home-image@2x.png';
import { SignupForm } from '@/features/Auth';

export default {
  name: 'Signup',
  components: {
    SignupForm,
  },
  data() {
    return {
      homeImg,
      loginText: 'Already a member?',
      loginLink: {
        text: 'Log in here',
        to: { name: 'login' },
      },
    };
  },
  computed: {
    formContentStyle() {
      return {
        width: this.$vuetify.breakpoint.xs ? '280px' : '400px',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  height: 100vh;

  & > * {
    flex: 1;
  }
}
.picture-container {
  position: relative;
  width: 45%;
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 55%;
}
.switch {
  text-align: left;
  margin-top: 24px;
  width: 100%;
}
</style>
